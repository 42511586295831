import "../style/Package.scss";

function Package(props) {
  return (
    <div className="package-container">
      <div className="package-name">
        <div className="package-text">
          <h3>{props.name}</h3>
          <p>{props.get}</p>
        </div>
        <div className="discount">
          <h3>{props.discount}</h3>
          <p>off</p>
        </div>
      </div>

      <div className="price-container">
        <div className="price-text">
          <h3>{props.price}</h3>
          <p>USD</p>
        </div>
        <div>
          <button>Buy</button>
        </div>
      </div>
    </div>
  );
}

export default Package;
