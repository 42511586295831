import React from "react";
import "../style/CrewCastleDetails.scss";

function CrewCastleDetails({ phaseNumber, castleName, castleData, onBackClick, fetchCrewMembers }) {
  return (
    <div className="crew-castle-details">
      <h2 className="castle-details-header">{castleName} Castle Crew Ranking</h2>
      <button className="back-button" onClick={onBackClick}>
        Back to Crew Ranking
      </button>
      <table className="castle-crew-table">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Name</th>
            {phaseNumber === 1 ? <th>Boss Points</th> : null} {/* Show Boss Points header only in phase 1 */}
          </tr>
        </thead>
        <tbody>
          {castleData.map((crew, index) => (
            <tr
              key={index}
              onClick={() => {
                console.log("Clicked crew data:", crew); // Debugging: check crew data
                if (crew.crew_id) {
                  fetchCrewMembers(crew.crew_id, crew.crew_name);  // Ensure crew_id is passed correctly
                } else {
                  console.error("Crew ID is missing or undefined"); // Log error if crew_id is not found
                }
              }}
              className={index % 2 === 0 ? "row-even" : "row-odd"}
              style={{ cursor: "pointer" }} // Make row clickable
            >
              <td>{index + 1}</td>
              <td>{crew.crew_name}</td>
              {phaseNumber === 1 ? <td>{crew.boss_kills}</td> : null} {/* Show Boss Points only in phase 1 */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CrewCastleDetails;
