import "../style/Dashboard.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import token from "../img/token.png";
import emblemplus from "../img/emblemplus.png";
import emblem from "../img/emblem.png";
import freeuser from "../img/freeuser.webp";
import Payment from './Payment';
import ChangeUsernamePopup from './ChangeUsernamePopup';

function Dashboard() {
    const navigate = useNavigate();
    let logged_in = Boolean(localStorage.getItem("logged_in") === "true");
    useEffect(() => {
        if (!logged_in) {
            navigate("/Login");
            window.location.reload(false);
        }
    }, [logged_in, navigate]);

    const accType = localStorage.getItem("acc_type");
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State to manage popup visibility

    const openPopup = () => {
        setIsPopupOpen(true);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div className="content-container">
            <h1 className="h1-dashboard">Account Information</h1>
            <div className="data-container">
                <div className="data-item">
                    <label htmlFor="username">Account Username:</label>
                    <span id="username">{localStorage.getItem("username")}</span>
                    <button className="change-username-btn" onClick={openPopup}>Change Username</button>
                </div>
                <div className="data-item">
                    <label htmlFor="acc_id">Account ID:</label>
                    <span id="acc_id">{localStorage.getItem("acc_id")}</span>
                </div>
                <div className="data-item">
                    <label htmlFor="acc_type">Account Type:</label>
                    <span id="acc_type">{accType}</span>
                    {accType === "Emblem Plus User" && (
                        <img src={emblemplus} width="50" alt="Emblem Plus User" />
                    )}
                    {accType === "Premium User" && (
                        <img src={emblem} width="30" alt="Premium User" />
                    )}
                    {accType === "Free User" && (
                        <img src={freeuser} width="50" alt="Free User" />
                    )}
                </div>
                <div className="data-item">
                    <label htmlFor="tokens">Tokens:</label>
                    <span id="tokens">{localStorage.getItem("tokens")}</span>
                    <img src={token} width="25" alt="Token" />
                </div>
            </div>
            <Payment />

            {isPopupOpen && (
                <ChangeUsernamePopup onClose={closePopup} />
            )}
        </div>
    );
}

export default Dashboard;
