import React, { useState } from 'react';
import $ from 'jquery';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  Modal,
  IconButton,
  Snackbar,
  Alert
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';

const darkBlueTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#111827",
      paper: "#1c1f26",
    },
    text: {
      primary: "#c9d1d9",
      secondary: "#8b949e",
    },
    primary: {
      main: "#1e3a8a",
    },
    secondary: {
      main: "#3b82f6",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#262a34",
          borderRadius: 8,
        },
      },
    },
  },
});

const ResetPassword = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const showAlert = (severity, message) => {
    setAlert({ open: true, severity, message });
  };

  const handleCloseAlert = () => {
    setAlert({ open: false, severity: '', message: '' });
  };

  const sendResetCode = () => {
    $.ajax({
      type: 'POST',
      url: 'https://playninjalegends.com/account/ResetPassword.php', // Replace with your actual endpoint
      data: { email, action: "sendRecoveryCode"},
      success(data) {
        if (data.status == 1){
          showAlert('success', data.result);
          setStep(2);
        }else{
          showAlert('error', data.error);
        }
      },
      error(data) {
        showAlert('error', data.error);
      }
    });
  };

  const verifyResetCode = () => {
    $.ajax({
      type: 'POST',
      url: 'https://playninjalegends.com/account/ResetPassword.php', // Replace with your actual endpoint
      data: { email, resetCode, action: "verifyRecoveryCode" },
      success(data) {
        if (data.status == 1){
          showAlert('success', data.result);
          setStep(3);
        }else{
          showAlert('error', data.error);
        }
      },
      error(data) {
        showAlert('error', data.error);
      }
    });
  };

  const changePassword = () => {
    if (newPassword !== confirmPassword) {
      showAlert('error', 'Passwords do not match');
      return;
    }

    $.ajax({
      type: 'POST',
      url: 'https://playninjalegends.com/account/ResetPassword.php', // Replace with your actual endpoint
      data: { email, resetCode, newPassword, action: "resetPassword" },
      success(data) {
        if (data.status == 1){
          showAlert('success', 'Your password has been changed');
          onClose(); // Close the popup after changing password
        }else{
          showAlert('error', data.error);
        }
      },
      error(data) {
        showAlert('error', data.error);
      }
    });
  };

  return (
    <ThemeProvider theme={darkBlueTheme}>
      <Modal
        open
        onClose={onClose}
        aria-labelledby="reset-password-title"
        aria-describedby="reset-password-description"
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={8}
            sx={{
              p: 4,
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'background.paper',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography component="h1" variant="h5" id="reset-password-title">
              {step === 1 && 'Reset Password'}
              {step === 2 && 'Verify Reset Code'}
              {step === 3 && 'Change Password'}
            </Typography>

            {step === 1 && (
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={handleChange(setEmail)}
                InputLabelProps={{ style: { color: "#8b949e" } }}
                sx={{ mt: 2 }}
              />
            )}

            {step === 2 && (
              <TextField
                margin="normal"
                required
                fullWidth
                id="resetCode"
                label="Reset Code"
                name="resetCode"
                autoComplete="off"
                autoFocus
                value={resetCode}
                onChange={handleChange(setResetCode)}
                InputLabelProps={{ style: { color: "#8b949e" } }}
                sx={{ mt: 2 }}
              />
            )}

            {step === 3 && (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type="password"
                  id="newPassword"
                  autoComplete="new-password"
                  value={newPassword}
                  onChange={handleChange(setNewPassword)}
                  InputLabelProps={{ style: { color: "#8b949e" } }}
                  sx={{ mt: 2 }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={handleChange(setConfirmPassword)}
                  InputLabelProps={{ style: { color: "#8b949e" } }}
                  sx={{ mt: 2 }}
                />
              </>
            )}

            {step === 1 && (
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2 }}
                color="primary"
                onClick={sendResetCode}
              >
                Send Reset Code
              </Button>
            )}

            {step === 2 && (
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2 }}
                color="primary"
                onClick={verifyResetCode}
              >
                Verify Code
              </Button>
            )}

            {step === 3 && (
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2 }}
                color="primary"
                onClick={changePassword}
              >
                Change Password
              </Button>
            )}
          </Paper>
        </Container>
      </Modal>
      
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default ResetPassword;
