import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  CssBaseline,
  Paper,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ResetPassword from "./ResetPassword";

const darkBlueTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#111827",
      paper: "#1c1f26",
    },
    text: {
      primary: "#c9d1d9",
      secondary: "#8b949e",
    },
    primary: {
      main: "#1e3a8a",
    },
    secondary: {
      main: "#3b82f6",
    },
  },
  typography: {
    fontSize: 16, // Base font size increased for overall larger text
    h1: {
      fontSize: '2.4rem', // Increase heading size
    },
    h5: {
      fontSize: '1.8rem', // Increase subheading size
    },
    body1: {
      fontSize: '1.2rem', // Increase body text size
    },
    body2: {
      fontSize: '1rem', // Slightly larger body2 text (e.g., for helper texts)
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#262a34",
          borderRadius: 8,
          fontSize: '1.2rem', // Increase input text size
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem', // Increase button text size
        },
      },
    },
  },
});

function Login() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    const logged_in = localStorage.getItem("logged_in") === "true";
    if (logged_in) {
      navigate("/Dashboard");
      window.location.reload(false);
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);

    // Validate email
    const email = form.find("#mail").val();
    if (!validateEmail(email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        if (data.logged_in) {
          localStorage.setItem("logged_in", data.logged_in);
          localStorage.setItem("acc_id", data.acc_id);
          localStorage.setItem("username", data.username);
          localStorage.setItem("sessionkey", data.sessionkey);
          localStorage.setItem("tokens", data.tokens);

          const accType = data.acc_type === 2 ? "Emblem Plus User" : data.acc_type === 1 ? "Premium User" : "Free User";
          localStorage.setItem("acc_type", accType);

          navigate("/Dashboard");
          window.location.reload(false);
        } else {
          localStorage.setItem("logged_in", "false");
          MySwal.fire({
            title: <strong>An Error Occurred!</strong>,
            html: <i>Wrong email or password!</i>,
            icon: 'error'
          });
        }
      },
      error() {
        MySwal.fire({
          title: <strong>An Error Occurred!</strong>,
          html: <i>Internal error!</i>,
          icon: 'error'
        });
      }
    });
  };

  const validateEmail = (email) => {
    // Simple regex for email validation
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleResetPasswordClick = (e) => {
    e.preventDefault();
    setIsResetPasswordOpen(true);
  };

  const closeResetPasswordPopup = () => {
    setIsResetPasswordOpen(false);
  };

  return (
    <ThemeProvider theme={darkBlueTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="md" sx={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box
          component={Paper}
          elevation={8}
          p={6} // Increased padding
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {/* Warning Message */}
          <Typography variant="body1" color="error" align="center" sx={{ mb: 2 }}>
            Currently a work in progress, you won't be able to log in.
          </Typography>

          <Typography component="h1" variant="h5">
            Sign In
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Please enter your credentials to access your account
          </Typography>
          <Box component="form" action="https://playninjalegends.com/account/Login.php" method="post" onSubmit={handleSubmit} noValidate sx={{ mt: 4, width: "100%" }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="mail"
              label="Email Address"
              name="mail"
              autoComplete="email"
              autoFocus
              error={emailError}
              helperText={emailError ? "Please enter a valid email." : ""}
              InputLabelProps={{ style: { color: "#8b949e" } }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputLabelProps={{ style: { color: "#8b949e" } }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 5, mb: 4 }} // Increased margin
              color="primary"
            >
              Login
            </Button>
            <Box textAlign="center">
              <Link href="#" variant="body2" onClick={handleResetPasswordClick} color="secondary">
                Forgot Password?
              </Link>
            </Box>
          </Box>
        </Box>
        {isResetPasswordOpen && <ResetPassword onClose={closeResetPasswordPopup} />}
      </Container>
    </ThemeProvider>
  );
}

export default Login;
