import "../style/Character_card.scss";

export const Character_card = (props) => {
  return (
    <div className="container ">
      <div className="text-container">
        <h2>{props.header}</h2>
        <p>{props.text}</p>
      </div>
      <div className="img-container">
        <img src={props.img} alt="" />
      </div>
    </div>
  );
};

export default Character_card;
