import "../style/Congratulation.scss";

function Congratulation() {
  return (
    <div className="congrats">
      <div className="congrats-text">
        <p>
          Congratulations on exploring Ninja Rift and discovering its exciting
          features! But the adventure doesn't end here. We're constantly working
          to bring you new updates, additional quests, and thrilling challenges.
          Stay tuned for upcoming content that will expand the world of Ninja
          Rift and provide even more opportunities for epic battles and amazing
          rewards. Join our community, follow our social media channels, and be
          among the first to experience the future of Ninja Rift. There's so
          much more to come!
        </p>
      </div>
      <div className="join-us">
        <button>
        <a href="https://discord.gg/ninjarift" target="_blank"><ion-icon name="logo-discord"></ion-icon>JOIN DISCORD</a>
        </button>
        <button>
        <a href="https://www.facebook.com/PlayNinjaRift" target="_blank"><ion-icon name="logo-facebook"></ion-icon>FOLLOW ON FACEBOOK</a>
        </button>
        <button>
        <a href="https://www.youtube.com/@playninjarift" target="_blank"><ion-icon name="logo-youtube"></ion-icon>SUBSCRIBE ON YOUTUBE</a>
        </button>
        <button>
        <a href="https://www.instagram.com/playninjarift" target="_blank"><ion-icon name="logo-instagram"></ion-icon>FOLLOW ON INSTAGRAM</a>
        </button>
        <button>
        <a href="https://www.tiktok.com/@playninjarift" target="_blank"><ion-icon name="logo-tiktok"></ion-icon>FOLLOW ON TIKTOK</a>
        </button>
      </div>
    </div>
  );
}

export default Congratulation;
