import "../style/Payment.scss";
import Tokens from "./Tokens";
import emblemplus from "../img/emblemplus.png";
import emblem from "../img/emblem.png";
import $ from "jquery";

function Payment() {
  const accType = localStorage.getItem("acc_type");

  return (
    <div className="payment-container">
      <div className="payment-header">
        Payment method: PayPal
      </div>

      {accType === "Free User" && (
        <>
          <div className="buy-emblem">
            <div className="emblem">
              <img src={emblem} alt="Ninja Emblem" />
              <div className="emblem-text">
                <h3>Ninja Emblem</h3>
                <p>Enjoy premium features</p>
              </div>
            </div>
            <div className="emblem-price">
              <div className="price-text">
                <h3>9.99</h3>
                <p>USD</p>
              </div>
              <div>
                <button>Buy</button>
              </div>
            </div>
          </div>

          <br />

          <div className="buy-emblem-plus">
            <div className="emblem">
              <img src={emblemplus} alt="Emblem+" />
              <div className="emblem-text">
                <h3>Emblem+</h3>
                <p>Enjoy all premium features and more</p>
              </div>
            </div>
            <div className="emblem-price">
              <div className="price-text">
                <h3>19.99</h3>
                <p>USD</p>
              </div>
              <div>
                <button>Buy</button>
              </div>
            </div>
          </div>
        </>
      )}

      {accType === "Premium User" && (
        <div className="buy-emblem-plus">
          <div className="emblem">
            <img src={emblemplus} alt="Emblem+" />
            <div className="emblem-text">
              <h3>Emblem+</h3>
              <p>Enjoy all premium features and more</p>
            </div>
          </div>
          <div className="emblem-price">
            <div className="price-text">
              <h3>9.99</h3>
              <p>USD</p>
            </div>
            <div>
              <button>Buy</button>
            </div>
          </div>
        </div>
      )}

      <div className="tokens">
        <div className="tokens-container">
          <Tokens amount="30 000" price="49.99" />
          <Tokens amount="13 500" price="24.99" />
          <Tokens amount="5000" price="9.99" />
          <Tokens amount="2200" price="4.99" />
        </div>
      </div>
    </div>
  );
}

export default Payment;
