import Landing from './Landing';
import Launch from './Launch';
import About from './About';
import Character from './Character';
import Congratulation from './Congratulation';

function AllComps() {
    return(
        <div>
            <Landing />
            <About />
            <Character />
            <Congratulation />
        </div>
    )
}

export default AllComps;