import React from 'react';
import Package from './Package';  // Importing your Package component
import emblem from '../img/emblem.png';  // Adjust the path to your emblem image
import { Link } from 'react-router-dom';

function AddPackages() {
  return (
    <div className="payment-container">
      <Link to="/Resellers" className="back-button">Back to Reseller Dashboard</Link>
      {localStorage.getItem("acc_type") !== "Premium User" && (
        <div className="buy-emblem">
          <div className="emblem">
            <img src={emblem} alt="" />
            <div className="emblem-text">
              <h3>Ninja Emblem</h3>
              <p>Enjoy premium features</p>
            </div>
          </div>
          <div className="emblem-price">
            <div className="price-text">
              <h3>9.99</h3>
              <p>USD</p>
            </div>
            <div>
              <button>Add</button>
            </div>
          </div>
        </div>
      )}
      <div className="tokens">
        <h2>Token Packages</h2>
        <div className="tokens-container">
          <Package name="30,000 tokens and 100 rolls" get="" discount="33%" price="49.99" />
          <Package name="13,500 tokens and 40 rolls" get="" discount="33%" price="24.99" />
          <Package name="5,000 tokens and 20 rolls" get="" discount="33%" price="9.99" />
          <Package name="2,200 tokens" get="" discount="" price="4.99" />
          <Package name="300 tokens" get="" discount="" price="0.99" />
        </div>
      </div>
      <div className="rolls">
        <h2>Roll Packages</h2>
        <div className="tokens-container">
          <Package name="3400 rolls" get="" discount="" price="49.99" />
          <Package name="1600 rolls" get="" discount="" price="24.99" />
          <Package name="600 rolls" get="" discount="" price="9.99" />
        </div>
      </div>
      <div className="battle-pass">
        <h2>Battle Pass Packages</h2>
        <div className="tokens-container">
          <Package name="Elite Pass" get="" discount="" price="9.99" />
          <Package name="Elite Pass and 350 stamina rolls" get="" discount="" price="14.99" />
          <Package name="Elite Pass on all 6 characters" get="" discount="" price="24.99" />
        </div>
      </div>
    </div>
  );
}

export default AddPackages;
