import './App.scss';
import AllComps from './components/AllComps';
import Header from "./components/Header";
import Footer from './components/Footer';
import Account from './components/Account';
import Dashboard from './components/Dashboard';
import Payment from './components/Payment';
import ClanRanking from './components/ClanRanking';
import CrewRanking from './components/CrewRanking';
import ArenaRanking from './components/ArenaRanking';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import DeleteAccount from './components/DeleteAccount';
import PatchNotes from './components/PatchNotes';
import Login from './components/Login';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AddPackages from './components/AddPackages';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<AllComps />}></Route>
          <Route exact path="/Account" element={<Account />}></Route>
          <Route exact path="/Dashboard" element={<Dashboard />}></Route>
          <Route exact path="/ClanRanking" element={<ClanRanking />}></Route>
          <Route exact path="/CrewRanking" element={<CrewRanking />}></Route>
          <Route exact path="/ArenaRanking" element={<ArenaRanking />}></Route>
          <Route exact path="/Payment" element={<Payment />}></Route>
          <Route exact path="/AddPackages" element={<AddPackages />}></Route>
          <Route path="/TermsAndConditions" element={<TermsAndConditions />}></Route>
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}></Route>
          <Route path="/DeleteAccount" element={<DeleteAccount />}></Route>
          <Route path="/PatchNotes" element={<PatchNotes />}></Route>
          <Route path="/Login" element={<Login />}></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}



export default App;
