import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  Alert,
  Snackbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../style/Account.scss"; // Optional: for any additional custom styling

const darkBlueTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#111827",
      paper: "#1c1f26",
    },
    text: {
      primary: "#c9d1d9",
      secondary: "#8b949e",
    },
    primary: {
      main: "#1e3a8a",
    },
    error: {
      main: "#d32f2f",
    },
  },
  typography: {
    fontSize: 16, // Base font size increased for better readability on mobile
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#262a34",
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
        },
      },
    },
  },
});

function Account() {
  const navigate = useNavigate();
  const [isBanned, setIsBanned] = useState(false);
  const [banMessage, setBanMessage] = useState("");
  const [banFee, setBanFee] = useState(null); // null means no chance to unban
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

  const showAlert = (severity, message) => {
    setAlert({ open: true, severity, message });
  };

  const handleCloseAlert = () => {
    setAlert({ open: false, severity: '', message: '' });
  };

  useEffect(() => {
    let logged_in = Boolean(localStorage.getItem("logged_in") === "true");
    if (logged_in) {
      navigate("/Dashboard");
      window.location.reload(false);
    }

    $.ajax({
      type: "GET",
      url: "https://playninjalegends.com/account/checkBanStatus", 
      success(data) {
        if (data.isBanned) {
          setIsBanned(true);
          setBanFee(data.banFee); // Expected values: 25, 50, or null
          setBanMessage(data.banMessage || "Your account has been banned! Please take action to unban!");
        }
      },
      error() {
        console.error("Error checking ban status.");
      }
    });
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        if (data.logged_in) {
          localStorage.setItem("logged_in", data.logged_in);
          localStorage.setItem("acc_id", data.acc_id);
          localStorage.setItem("sessionkey", data.sessionkey);
          localStorage.setItem("tokens", data.tokens);
          localStorage.setItem("acc_type", data.acc_type !== 0 ? "Premium User" : "Free User");
          navigate("/Dashboard");
          window.location.reload(false);
        } else {
          showAlert('error', 'Wrong username or password!');
        }
      },
      error() {
        showAlert('error', 'Internal error!');
      }
    });
  };

  return (
    <ThemeProvider theme={darkBlueTheme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          padding: 2,
          textAlign: "center",
        }}
      >
        {isBanned && (
          <Alert
            severity="error"
            sx={{
              mb: 2,
              width: "100%",
              textAlign: "left",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {banMessage}
            {banFee && (
              <Button
                variant="contained"
                color="error"
                href={`https://playninjalegends.com/account/unban?fee=${banFee}`}
                sx={{ ml: 2 }}
              >
                Pay ${banFee} Now
              </Button>
            )}
            {!banFee && <strong>No chance to unban.</strong>}
          </Alert>
        )}
        <Paper
          elevation={8}
          sx={{
            padding: 4,
            width: "100%",
            maxWidth: 400,
            bgcolor: "background.paper",
            textAlign: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form
            action="https://playninjalegends.com/account/Login.php"
            method="post"
            onSubmit={(event) => handleSubmit(event)}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              InputLabelProps={{ style: { color: "#8b949e" } }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputLabelProps={{ style: { color: "#8b949e" } }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Typography variant="body2" color="textSecondary">
              Please enter your username and password to log in.
            </Typography>
          </form>
        </Paper>

        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}

export default Account;
