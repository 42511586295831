import "../style/Character.scss";
import Character_card from "./Character_card";
import one from "../img/one.png";
import two from "../img/two.png";
import three from "../img/three.png";
import four from "../img/four.png";
import five from "../img/five.png";
import six from "../img/six.png";

function Character() {
  return (
    <div className="card-container">
      <Character_card
        img={one}
        header="Create Your Character"
        text="Begin your Ninja Rift saga by creating your character. Name your character, 
        select a gender, choose a hair style, pick a hair color, and start with an elemental power.   
        Personalize your character and step into the Rift."
      />

      <Character_card
        className="second"
        img={two}
        header="Power Up Your Character"
        text="In your Ninja Rift profile, check out your character's stats. 
        Easily add points to elements and make your 
        character stronger for all the fun adventures ahead.."
      />
      <Character_card
        img={three}
        header="Gear Up for Battle"
        text="In the Gear section, outfit your character with weapons, clothing, 
        back items, and accessories. Mix and match to find the perfect combination 
        that will make your character both stylish and formidable."
      />
      <Character_card
        img={four}
        header="Customize Your Skills"
        text="In the Skills section, unlock the potential of your character by customizing their abilities. 
        Explore the range of skills available and configure the perfect combination for optimal performance 
        in challenges. Strategically assign and refine your skills to create a formidable character capable 
        of overcoming any obstacle that awaits."
      />
      <Character_card
        img={five}
        header="Complete Quests"
        text="In the Missions section, challenge yourself with quests that will test your skills and courage. 
        Choose from a variety of exciting missions and dive into epic battles against formidable enemies. 
        Defeat your opponents with mastery and earn valuable rewards as recognition for your bravery and prowess."
      />
      <Character_card
        img={six}
        header="Master Your Skills at the Academy"
        text="In the Academy section, train and refine your abilities to become a true master. 
        Learn new skills, hone your techniques, and push your limits to unlock even greater 
        potential. Whether you're perfecting existing abilities or acquiring new ones, the Academy is your gateway to mastery."
      />
    </div>
  );
}

export default Character;
