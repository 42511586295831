import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaBug, FaStar } from 'react-icons/fa';
import '../style/PatchNotes.scss';
import patchNotesData from './patchNotesData.json';  // Adjust the import path as necessary

const PatchNotes = () => {
  const [expandedVersion, setExpandedVersion] = useState(null);

  const toggleExpansion = (version) => {
    setExpandedVersion(expandedVersion === version ? null : version);
  };

  const renderNotes = (notes) => {
    return notes.map((note, index) => (
      <li key={index} className={`note ${note.type}`}>
        {note.type === 'new' ? <FaStar className="icon new-icon" /> : <FaBug className="icon bug-icon" />}
        {note.text}
      </li>
    ));
  };

  const renderThumbnail = (thumbnail) => {
    try {
      // Dynamically require the image
      const image = require(`../img/PatchNotes/${thumbnail}`);
      return <img src={image} alt={`Thumbnail`} className="expanded-thumbnail" />;
    } catch (error) {
      console.error('Error loading thumbnail:', error);
      return <div className="thumbnail-error">Thumbnail not available</div>;
    }
  };

  return (
    <div className="patch-notes-container">
      <div className="patch-notes-list">
        {patchNotesData.map((patch) => (
          <div key={patch.version} className="patch-notes-item">
            <div className="patch-notes-header" onClick={() => toggleExpansion(patch.version)}>
              <h3>Version {patch.version} - {patch.date}</h3>
              <div className="toggle-icon">
                {expandedVersion === patch.version ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            </div>
            {expandedVersion === patch.version && (
              <div className="patch-notes-content">
                {renderThumbnail(patch.thumbnail)}
                <ul>{renderNotes(patch.notes)}</ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PatchNotes;
