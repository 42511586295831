import React from "react";
import "../style/DeleteAccount.scss";

function DeleteAccount() {
  return (
    <div className="delete-account">
      <h1>Delete Your Account</h1>
      
      <div className="section">
        <p>If you wish to delete your Ninja Rift account, please contact our support team for assistance. We understand that sometimes it’s necessary to move on, and we're here to help you with the process.</p>
        
        <p>You can reach out to us through the following channels:</p>
        <ul>
          <li>Email: <a href="mailto:support@ninjarift.com">support@ninjarift.com</a></li>
          <li>Facebook: <a href="https://www.facebook.com/PlayNinjaRift" target="_blank" rel="noopener noreferrer">Reach out on Facebook</a></li>
          <li>Discord: <a href="https://discord.gg/AT5VHtNrQf" target="_blank" rel="noopener noreferrer">Reach out on Discord</a></li>
        </ul>

        <p>When contacting us, please provide the following information to help us verify your account and process your request efficiently:</p>
        <ul>
          <li>Your username or the email address associated with your account</li>
          <li>Reason for account deletion (optional, but appreciated)</li>
          <li>Any other relevant information that can help us verify your identity</li>
        </ul>

        <p>Once we receive your request, our support team will verify your information and initiate the account deletion process. This process may take a few days to complete, and we will notify you once your account has been successfully deleted.</p>
        
        <p>Please note that account deletion is permanent and cannot be undone. You will lose access to all your data, progress, and any in-game purchases. Make sure to back up any important information before proceeding.</p>
      </div>
    </div>
  );
}

export default DeleteAccount;
