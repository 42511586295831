import "../style/Tokens.scss";
import token from "../img/token.png";

function Tokens(props) {
  return (
    <div className="package-container">
      <div className="token-amount">
        <div className="img">
          <img src={token} alt="" />
          <div>
            <h3>{props.amount}</h3>
            <p>Tokens</p>
          </div>
        </div>
        <div className="discount">
          <h3>{props.discount}</h3>
          <p>{props.off}</p>
        </div>
      </div>

      <div className="price-container">
        <div className="price-text">
          <h3>{props.price}</h3>
          <p>USD</p>
        </div>
        <div>
          <button>Buy</button>
        </div>
      </div>
    </div>
  );
}

export default Tokens;
