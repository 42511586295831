import React, { useState } from "react";
import $ from 'jquery';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  Modal,
  Snackbar,
  Alert,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const darkBlueTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#111827",
      paper: "#1c1f26",
    },
    text: {
      primary: "#c9d1d9",
      secondary: "#8b949e",
    },
    primary: {
      main: "#1e3a8a",
    },
    secondary: {
      main: "#3b82f6",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "#262a34",
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
        },
      },
    },
  },
});

const ChangeUsernamePopup = ({ onClose }) => {
  const [newUsername, setNewUsername] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

  const showAlert = (severity, message) => {
    setAlert({ open: true, severity, message });
  };

  const handleCloseAlert = () => {
    setAlert({ open: false, severity: '', message: '' });
  };

  const handleSubmit = async () => {
    if (newUsername.length <= 3) {
      showAlert('error', 'Username is too short. Must be more than 3 characters.');
      return;
    }
    if (password1 !== password2) {
      showAlert('error', 'Passwords do not match!');
      return;
    }
    $.ajax({
      type: 'POST',
      url: 'https://playninjalegends.com/account/ChangeUsername.php',
      data: { username: newUsername, password: password1, current_username: localStorage.getItem("username") },
      success(data) {
        if (data.status == 1){
          localStorage.setItem("username", newUsername);
          var new_tokens = localStorage.getItem("tokens") - 2000;
          localStorage.setItem("tokens", new_tokens);
          showAlert('success', data.result);
          onClose();
        }else{
          showAlert('error', data.error);
        }
      },
      error(data) {
        showAlert('error', data.error);
      }
    });
  };

  return (
    <ThemeProvider theme={darkBlueTheme}>
      <Modal
        open
        onClose={onClose}
        aria-labelledby="change-username-title"
        aria-describedby="change-username-description"
      >
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={8}
            sx={{
              p: 4,
              width: '100%',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              bgcolor: 'background.paper',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography component="h1" variant="h5" id="change-username-title">
              Change Username
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 2 }}>
              Note: You will be charged 2000 Token for the username change.
            </Typography>
            <Box component="form" noValidate sx={{ mt: 2, width: '100%' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="new-username"
                label="New Username"
                name="newUsername"
                autoComplete="off"
                autoFocus
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                InputLabelProps={{ style: { color: "#8b949e" } }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password1"
                label="Password"
                type="password"
                id="password1"
                autoComplete="current-password"
                value={password1}
                onChange={(e) => setPassword1(e.target.value)}
                InputLabelProps={{ style: { color: "#8b949e" } }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Confirm Password"
                type="password"
                id="password2"
                autoComplete="current-password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                InputLabelProps={{ style: { color: "#8b949e" } }}
              />
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 4, mb: 2 }}
                color="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
              <Button
                type="button"
                fullWidth
                variant="outlined"
                sx={{ mt: 1 }}
                color="secondary"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Box>
          </Paper>
        </Container>
      </Modal>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default ChangeUsernamePopup;
